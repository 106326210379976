import { get, post } from 'src/utils/request'

const api_v1 = '/newretail'

// nvr管理页
export const getScanNvrs = get(api_v1 + '/api/solution_package/sp2/scanNvrs')
export const bindToBox = post(api_v1 + '/api/nvr/bindToBox')
export const unbindFromBox = post(api_v1 + '/api/nvr/unbindFromBox')

// nvr  tab页
export const getNvrList = get(api_v1 + '/api/solution_package/sp2/getNvrList')
export const bindCamera4Nvr = post(api_v1 + '/api/solution_package/sp2/bindCamera4Nvr')
export const unbindCamera4Nvr = post(api_v1 + '/api/solution_package/sp2/unbindCamera4Nvr')
export const getNvrPlayUrl = get(api_v1 + '/api/solution_package/sp2/play')
export const removeNvr = post(api_v1 + '/api/solution_package/sp2/removeNvr')

// nvr设置页
export const getNvrInfo = get(api_v1 + '/api/nvr/getNvrInfo') //
export const updateNvrInfo = post(api_v1 + '/api/nvr/updateNvrInfo') // 修改NVR信息
export const scanChannelsByNvr = get(api_v1 + '/api/nvr/scanChannelsByNvr') // 扫描NVR 信息
export const bindToNvr = post(api_v1 + '/api/nvr/bindToNvr') // 绑定
export const unbindFromNvr = post(api_v1 + '/api/nvr/unbindFromNvr') // 解绑
export const updateChannel = post(api_v1 + '/api/nvr/updateChannel') // 修改NVR-通道的名称
export const getNvrCameraList = get(api_v1 + '/api/solution_package/sp2/getNvrCameraList')
export const isChannelIdExist = get(api_v1 + '/api/nvr/isChannelIdExist') // 检查通道号是否已存在
