<template>
  <div class="app-container">
    <el-steps :active="active" :align-center="true" finish-status="success">
      <el-step title="填写配置"> </el-step>
      <el-step title="添加设备"></el-step>
      <el-step title="完成"></el-step>
    </el-steps>
    <div class="step-content">
      <step1 v-show="active === 0" @change="handleChange" />
      <step2 v-show="active === 1" @change="handleChange" />
      <step3 v-show="active === 2" @change="handleChange" />
    </div>
  </div>
</template>

<script>
import step1 from './step1.vue'
import step2 from './step2.vue'
import step3 from './step3.vue'

export default {
  components: {
    step1,
    step2,
    step3,
  },
  data() {
    return {
      active: 0,
    }
  },
  methods: {
    handleChange(step) {
      this.active = step
    },
  },
}
</script>

<style lang="less" scoped>
.step-content {
  margin-top: 70px;
}
</style>
