<template>
  <div class="config">
    <el-form :model="form" :rules="rules" ref="form" label-width="180px" v-loading="isInitializing">
      <el-form-item label="客户" prop="cid">
        <el-select v-model="form.cid" filterable placeholder="请选择" @change="selectGroup" @focus="handleFocus">
          <el-option v-for="item in customerOptions" :key="item.cid" :label="item.cname" :value="item.cid"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="门店" class="required" prop="shopId">
        <div class="tree-wrap">
          <tree-select
            ref="treeSelector"
            :value="orgcode"
            :data="treeData"
            :loading="isFetching"
            :filter-node-method="filterNodeStore"
            :defaultExpandedKeys="expandedKeys"
            @selectedData="acceptData"
          ></tree-select>
        </div>
      </el-form-item>
      <el-form-item label="类型" prop="type">
        <el-radio-group v-model="form.type">
          <el-radio :disabled="form.type !== 6" :label="6">NVR</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item class="mt40">
        <el-button type="primary" :loading="isSubmitting" @click="handleNext">保存并继续</el-button>
        <el-button @click="() => this.$router.back()">取消</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { getCusList, orgShopTree } from 'src/api/legacy'
import TreeSelect from 'src/components/TreeSelect.vue'
import { isEmpty } from 'src/utils/method'
import { solution } from 'src/api/product'

export default {
  components: {
    TreeSelect,
  },
  data() {
    const validateShopId = (rule, value, callback) => {
      if (isEmpty(this.form.shopId)) {
        return callback(new Error('请选择'))
      } else {
        callback()
      }
    }
    const { boxId } = this.$route.query
    return {
      orgcode: null,
      customerOptions: [],
      treeData: [],
      cacheTreeData: {},
      isFetching: false,
      isInitializing: true,
      isSubmitting: false,
      form: {
        cid: null,
        shopId: null,
        type: parseInt(this.$route.query.type) || 6,
      },
      shows: {},
      rules: {
        cid: [
          {
            required: true,
            message: '请选择',
            trigger: 'change',
          },
        ],
        shopId: [
          {
            validator: validateShopId,
            trigger: 'change',
          },
        ],
        type: [
          {
            required: true,
            message: '请选择',
            trigger: 'change',
          },
        ],
      },
      expandedKeys: [0],
      boxId,
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      Promise.all([this.getGroup(), this.fetchShopData()]).then(() => {
        this.isInitializing = false
      })
    },
    handleNext() {
      this.isSubmitting = true
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          const { cid, shopId, type } = this.form
          const { status, data } = await solution.saveByShop({
            cid,
            shopId,
            productType: type,
          })

          if (status === 0) {
            // 盒子管理生成解决方案特殊处理
            if (!isEmpty(this.boxId)) {
              await solution.addNvr({
                boxId: this.boxId,
                productId: data.productId,
              })
            }

            await this.$store.dispatch('solution/updateStep_1', {
              ...this.form,
              ...this.shows,
              productId: data.productId,
            })
            this.$emit('change', 1)
          }
        }
        this.isSubmitting = false
      })
    },
    async fetchShopData() {
      const res = await orgShopTree()
      if (res.data && res.status === 0) {
        this.cacheTreeData = this.filterTreeData(res.data?.sublist ?? [])
      }
      this.isFetching = false
    },
    getGroup() {
      //查询所有门店
      return getCusList().then((res) => {
        if (res && res.status === 0) {
          this.customerOptions = res.data
        }
      })
    },
    acceptData(node) {
      this.form.shopId = node.data.shopId
      this.shows.shopName = node.data.name
    },
    selectGroup(value) {
      // 重置数据
      this.form.shopId = null
      this.$refs.treeSelector.label = ''

      if (value !== null) {
        const option = this.customerOptions.find((v) => value === v.cid)
        const filterData = this.cacheTreeData.find((item) => item.cid === option.cid)
        this.treeData = [filterData]
        this.orgcode = option.ccode
        this.expandedKeys = [option.ccode]
        this.shows.cname = option.cname
      } else {
        this.treeData = this.cacheTreeData
        this.orgcode = null
      }
    },
    handleFocus() {
      this.$refs.treeSelector.visible = false
    },
    filterTreeData(arr) {
      const func = (item) => {
        // 筛掉组织下无数据的节点
        if (item.orgType !== 1 && item.sublist.length === 0) {
          return false
        }
        if (item.sublist) {
          item.sublist = this.filterTreeData(item.sublist)
        }
        return true
      }
      return arr.filter(func)
    },
    filterNodeStore(value, data, node) {
      if (isEmpty(value)) {
        return true
      }
      let isPass =
        node.data &&
        node.data.name &&
        node.data.name.toLowerCase().indexOf(this.$refs.treeSelector.filterText.toLowerCase()) > -1
      const isCurrentOrg = data.prmType === 2 && data.orgcode.indexOf(value) === 0

      if (this.orgcode === '0' || isCurrentOrg) {
        if (isPass) {
          return true
        }
        if (node.level != 1 && node.parent) {
          return this.filterNodeStore(value, data, node.parent)
        }
      }

      return false
    },
  },
}
</script>

<style lang="less" scoped>
.tree-wrap {
  width: 200px;
}
</style>
