export const columns1 = [
  {
    label: 'No',
    key: 'No',
    minWidth: 80,
    sortable: true,
  },
  {
    label: 'SolutionID',
    key: 'productId',
    minWidth: 120,
    sortable: true,
  },
  {
    label: '运行状态',
    key: 'status',
    minWidth: 100,
    sortable: true,
  },
  {
    label: '类型',
    key: 'productType',
    sortable: true,
  },
  {
    label: '客户',
    key: 'cname',
    sortable: true,
  },
  {
    label: '门店',
    key: 'shopName',
    sortable: true,
  },
  {
    label: 'NVR数量',
    key: 'boxCount',
    minWidth: 100,
    align: 'right',
    sortable: true,
  },
  {
    label: '摄像头数量',
    key: 'cameraCount',
    align: 'right',
    minWidth: 110,
    sortable: true,
  },
  {
    label: '操作',
    key: 'operation',
    width: 100,
    fixed: 'right',
    disabled: true,
  },
]

export const brandNameMap = {
  DA_HUA: '大华',
  JVS:  '中维',
  HIKVISION: '海康威视'
}
