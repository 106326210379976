<template>
  <div class="config">
    <div class="operation">
      <div class="left">
        <el-button type="primary" @click="handleOpen">添加NVR</el-button>
      </div>
    </div>
    <el-table ref="singleTable" v-loading="isFetching" :data="tableData" @sort-change="sortChange" row-key="index">
      <el-table-column
        min-width="80"
        label="SIP用户认证ID"
        prop="mediaServerId"
        show-overflow-tooltip
      />
      <el-table-column
        min-width="100"
        label="SIP用户认证密码"
        prop="pwd"
        show-overflow-tooltip
      />
      <el-table-column
        min-width="100"
        label="NVR厂商"
        prop="brand"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          <span>{{brandNameMap[scope.row.brand]}}</span>
        </template>
      </el-table-column>
      <el-table-column fixed="right" label="操作" :min-width="100">
        <template slot-scope="scope">
<!--          <el-button @click="toCameraManage(scope.row)" type="text" size="small">摄像头管理</el-button>-->
          <el-button @click="handleDelete(scope.row)" type="text" size="small">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <dialog-add-box ref="addBox" :dialogVisible="dialogVisible" @confirm="handleConfirm" @close="handleClose" />
    <div class="footer">
<!--      <el-button class="cancel" @click="() => this.$router.back()">取消</el-button>-->
      <el-button type="primary" @click="handleNext">确定</el-button>
    </div>
  </div>
</template>

<script>
import { formatLabel } from 'src/utils/common'
import { isEmpty } from 'src/utils/method'
import DialogAddBox from './DialogAddBox'
import { mapState } from 'vuex'
import Status from 'src/components/Status'
import { productTypeList } from 'src/utils/dict'
import { getNvrList, removeNvr } from '@/api/nvr'
import { brandNameMap } from '@/views/nvrManage/common'

export default {
  components: {
    DialogAddBox,
    Status,
  },
  data() {
    const { boxId } = this.$route.query
    return {
      isFetching: false,
      dataSource: [],
      tableData: [],
      brandNameMap,
      prop: '',
      order: '',

      dialogVisible: false,
      boxId,
      productTypeList,
    }
  },
  computed: {
    ...mapState({
      step_1: (state) => state.solution.step_1,
    }),
  },
  watch: {
    step_1(data) {
      console.log('=====', data)
      if (data.productId) {
        this.fetchList()
      }
    },
  },
  methods: {
    isEmpty,
    formatLabel,
    async handleConfirm() {
      await this.fetchList()
      this.dialogVisible = false
    },
    handleClose() {
      this.dialogVisible = false
    },
    handleCancel() {
      this.dialogVisible = false
    },
    async handleDelete(row) {
      await removeNvr({productId: this.step_1.productId, nvrId: row.nvrId})
      await this.fetchList()
    },
    handleOpen() {
      this.dialogVisible = true
    },
    handleNext() {
      if (this.tableData.length === 0) {
        this.$message.warning('请至少添加一条数据')
        return
      }
      this.$router.back()
    },

    async fetchList() {
      this.isFetching = true
      try {
        if (!this.step_1.productId) return
        const { status, data } = await getNvrList({
          productId: this.step_1.productId,
        })
        if (status === 0) {
          this.tableData = data || []
        }
      } finally {
        this.isFetching = false
      }
    },
    sortChange(column) {
      const { prop, order } = column
      this.prop = prop
      this.order = order
      this.formatTableData()
    },
    formatTableData() {
      // this.tableData = generateTableData(this.dataSource, { order: this.order, prop: this.prop }, this.pageOptions)
      this.tableData = this.dataSource
    },
    toCameraManage(row) {
      this.$router.push({
        name: 'nvr-manage-cameraManage',
        query: {
          shopName: this.step_1.shopName,
          cname: this.step_1.cname,
          status: row.status,
          productId: this.step_1.productId,
          nvrId: row.nvrId,
        }
      })
    },
  },
}
</script>

<style lang="less" scoped>
.operation {
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
}
.footer {
  text-align: center;
  margin-top: 60px;
  .cancel {
    margin-right: 20px;
  }
}
</style>
