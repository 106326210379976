<template>
  <div class="dialog-add-box">
    <el-dialog
      title="添加NVR"
      class="dialog"
      :visible="dialogVisible"
      width="700px"
      destroy-on-close
      :close-on-click-modal="false"
      :before-close="handleClose"
    >
      <div v-loading="isFetchingBox">
        <el-form
          label-width="260px"
          :model="form"
          ref="form"
          :rules="rules"
        >
          <el-form-item
            label="SIP用户认证密码"
            prop="mediaPassword"
          >
            <el-input
              v-model="form.mediaPassword"
              placeholder="请输入SIP用户认证密码"
              style="width: 210px;"
            />
          </el-form-item>
          <el-form-item
            label="NVR厂商"
            prop="brand"
          >
            <el-select v-model="form.brand">
              <el-option
                value="DA_HUA"
                label="大华"
              />
              <el-option
                value="JVS"
                label="中维"
              />
              <el-option
                value="HIKVISION"
                label="海康威视"
              />
            </el-select>
          </el-form-item>
          <el-form-item
            label="SIP服务器IP"
          >
            49.232.54.205
          </el-form-item>
          <el-form-item
            label="SIP服务器端口"
          >
            {{form.mediaPort}}
          </el-form-item>
          <el-form-item
            label="服务器ID"
          >
            41010500002000000001
          </el-form-item>
          <el-form-item
            label="服务器域"
          >
            {{form.mediaDomain}}
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleCancel">取 消</el-button>
        <el-button :disabled="isDisabled" type="primary" :loading="isSubmitting" @click="handleConfirm"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { isEmpty } from 'src/utils/method'
import { solution } from 'src/api/product'
import { mapState } from 'vuex'
import { productTypeList } from 'src/utils/dict'
import { formatLabel } from 'src/utils/common'

export default {
  components: {

  },
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    hasSolution: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      total: 0,
      boxList: [],
      isFetchingBox: false,
      keyword: '',
      isSubmitting: false,
      productTypeList,
      form: {
        brand: '',
        mediaPassword: '123456',
        mediaDomain: '1101050048',
        mediaPort: 8115,
      },
      rules: {
        mediaPassword: [
          {required: true, message: '请输入SIP用户认证密码', trigger: 'blur'},
          {validator: (rule, value, callback) => {
            if (!/^[\da-zA-Z]{6}$/.test(value)) {
              callback(new Error('提示：6位，可输入数字、字母，区分大小写。'))
            } else {
              callback()
            }
          }, trigger: 'blur'}
        ],
        brand: [{required: true, message: '请选择NVR厂商', trigger: 'blur'}]
      }
    }
  },

  computed: {
    ...mapState({
      step_1: (state) => state.solution.step_1,
    }),
    productId() {
      if (this.hasSolution) {
        return this.$route.query.productId
      } else {
        return this.step_1.productId
      }
    },
    isDisabled() {
      return this.isFetchingBox
    },
  },
  watch: {
    dialogVisible() {
      if (this.dialogVisible) {
        setTimeout(() => {
          this.$refs.form.resetFields()
        })
      }
    }
  },
  methods: {
    isEmpty,
    formatLabel,
    async handleConfirm() {
      await this.$refs.form.validate()
      this.isSubmitting = true
      const { data } = await solution.addNvr({
        productId: this.productId,
        mediaPassword: this.form.mediaPassword,
        brand: this.form.brand,
        mediaDomain: this.form.mediaDomain,
        mediaPort: this.form.mediaPort
      })
      if (data) {
        this.$emit('confirm')
        this.$refs.form.resetFields()
      } else {
        this.$message.error('添加NVR失败')
      }
      this.isSubmitting = false
    },
    handleClose() {
      this.handleCancel()
    },
    beforeClose() {
      this.$refs.form.resetFields()
    },
    handleCancel() {
      this.beforeClose()
      this.$emit('close')
    },
  },
}
</script>

<style lang="less" scoped>
.dialog {
  width: 100%;
  /deep/ .el-table th.el-table__cell.is-leaf {
    background-color: #fff;
  }
}
/deep/ .el-table__row {
  &.disabled {
    background-color: #F2F6FC;
    color: #909399;
    pointer-events: none;
  }
}
/deep/ .el-table--enable-row-hover .el-table__body tr.abled:hover > td.el-table__cell {
  background-color: #c5dafa;
}
/deep/ .el-table__body tr.current-row.abled > td.el-table__cell {
  background-color: #c5dafa;
}
.head {
  padding: 10px 20px;
  font-weight: 600;
}
.pagina {
  text-align: right;
  padding: 24px 0 0;
}
</style>
